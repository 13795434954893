define("discourse/plugins/discourse-experiences/discourse/routes/detailes", ["exports", "discourse/lib/ajax", "@ember/routing/route"], function (_exports, _ajax, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  console.log('detail-es-route1');
  var _default = _exports.default = _route.default.extend({
    model(params) {
      return (0, _ajax.ajax)('/detailes/' + params.es_id + '.json').then(d => {
        console.log(d);
        let rtn = d.data;
        rtn.State = d.status;
        console.log(rtn);
        rtn.ESDataList = JSON.parse(rtn.ESDataList);
        return rtn;
      });
    }
  });
});