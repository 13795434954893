define("discourse/plugins/discourse-experiences/discourse/experiences-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  console.log('experiences-route-map3');
  function _default() {
    this.route('experiences', {
      path: '/experiences'
    });
    this.route('postes', {
      path: '/postes'
    });
    this.route('showes', {
      path: '/showes'
    });
    this.route('detailes', {
      path: '/detailes/:es_id'
    });
  }
});