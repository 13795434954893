define("discourse/plugins/discourse-experiences/discourse/templates/modal/delete", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#d-modal-body}}
    <h1>このESを削除してもよろしいですか？</h1>
    <h1>この操作は取り消せません。</h1>
    <button type="button" class="btn btn-danger" {{action "delete_es" model.ID}}>このESを削除する</button>
  {{/d-modal-body}}
  
  */
  {
    "id": "izjgv7pp",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h1\"],[12],[1,\"このESを削除してもよろしいですか？\"],[13],[1,\"\\n  \"],[10,\"h1\"],[12],[1,\"この操作は取り消せません。\"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[24,4,\"button\"],[4,[38,1],[[30,0],\"delete_es\",[30,0,[\"model\",\"ID\"]]],null],[12],[1,\"このESを削除する\"],[13],[1,\"\\n\"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-experiences/discourse/templates/modal/delete.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"d-modal-body\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-experiences/discourse/templates/modal/delete.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});